import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/shared-components/utility/loader';

// const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const SKUInformation = lazy(() => import('@pack/containers/SKU/Information'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@iso/shared-containers/Pages/404/404')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_403,
    component: lazy(() => import('@iso/shared-containers/Pages/403/403')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('@iso/shared-containers/Pages/500/500')),
  },
];

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        (
          children
        )
      }
    />
  );
}
export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>          
            <Route path='/SKU/:id'>
              <SKUInformation />
            </Route>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path="/SKU">
              <SKUInformation />
            </PrivateRoute>
            <Redirect to='/SKU' />
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
