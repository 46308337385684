import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as services from '@pack/services/inventory';
import * as moment from 'moment'

function* exportSuccess(fileName, data) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    yield put({ type: actions.EXPORT_LOADING_TURN_OFF });
}

export function* getInventoryBOMs() {
    yield takeLatest(actions.GET_INVENTORY_BOMS, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(services.getInventoryBOMs, model);
            if (response.data.success === true) {
                var { data } = response.data;
                yield put({
                    type: actions.GET_INVENTORY_BOMS_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.INVENTORY_LOADING_FALSE })
        }
    })
}
//#endregion

export default function* rootSaga() {
    yield all([             
        fork(getInventoryBOMs),
    ])
}