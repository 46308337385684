const actions = {
   
    INVENTORY_LOADING_FALSE: 'INVENTORY_LOADING_FALSE',   

    GET_INVENTORY_BOMS: 'GET_INVENTORY_BOMS',
    GET_INVENTORY_BOMS_SUCCESS: 'GET_INVENTORY_BOMS_SUCCESS',
    getInventoryBOMs: (model) => ({
        type: actions.GET_INVENTORY_BOMS,
        payload: { model }
    }),
}

export default actions;