import { combineReducers } from 'redux';
import App from '@iso/shared-redux/app/reducer';
import ThemeSwitcher from '@iso/shared-redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/shared-redux/languageSwitcher/reducer';
import Inventory from '@pack/redux/inventory/reducer';

export default combineReducers({
  
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Inventory,
});
