import actions from './actions';

const initState = {
    inventories: [],
    inventoryDetail: null,
    BOMs: [],
    Accessories: [],

    totalItems: 0,
    loading: false,
    loadingBOMs: false,
};

export default function inventoryReducer(state = initState, action) {
    switch (action.type) { 

        //#region BOM
        case actions.GET_INVENTORY_BOMS:
            return { ...state, loadingBOMs: true, totalItems: 0 };
        case actions.GET_INVENTORY_BOMS_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : [];
            let skuInfo = res.skuInfo;
            let boms = res.gems;
            let lstAccessories = res.accessories;
            return { ...state, BOMs: boms, inventoryDetail: skuInfo, Accessories: lstAccessories, loadingBOMs: false };
        }
        //#endregion

        case actions.INVENTORY_LOADING_FALSE:
            state = initState;
            return { ...state, loading: false, loadingBOMs: false };
        default:
            return state;
    }
}
