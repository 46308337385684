export const PUBLIC_ROUTE = {
  LANDING: '/',
  PAGE_404: '/404',
  PAGE_500: '/500',
  PAGE_403: '/403',
};

export const PRIVATE_ROUTE = {
  DASHBOARD: '/', 
};
